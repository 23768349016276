import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import ContentServices from "../components/Services/MenuContent";
import BaseLayout from "../components/global/BaseLayout";

function Services() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Menu">
      <div className="md:max-w-full w-full menuf">
        <TransparentHeader
          headertitle="Menu"
          Subheader="Menu"
          bgimg={`${rpdata?.stock?.[11]}`}
        />
        <h1 className="text-center p-10"><span className="text-white text-center">{rpdata?.dbSlogan?.[6]?.slogan}</span></h1>
        <ContentServices/>
      </div>
    </BaseLayout>
  );
}

export default Services;
