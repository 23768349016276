import React, { useContext, useState } from 'react'
import { GlobalDataContext } from '../../context/context'
import CardMenu from './CardMenu'

const Busqueda = () => {
    const { rpdata } = useContext(GlobalDataContext)

    const [cardProduct] = useState('')

    const [search] = useState('')

    const resultFilter = !search ? rpdata?.works : rpdata?.works?.filter((datos) => datos?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))

    return (
        <div className='h-full w-4/5 mx-auto py-16'>
            <div className='flex h-full'>
                <div className='w-full h-full'>
                    <div className='w-full h-full flex justify-center flex-wrap gap-10'>
                        {


                            cardProduct === '' ?
                                resultFilter?.map((item, index) => {
                                    return (

                                        <CardMenu
                                            key={index}
                                            imagen={item.gallery?.[0]}
                                            name={item.name}
                                            price={item.description}
                                            phone={rpdata?.dbPrincipal?.phones?.[0]?.phone.replaceAll("-","")}
                                        />


                                    )
                                })
                                :
                                <div className='flex flex-wrap gap-3 p-5'>
                                    {
                                        // eslint-disable-next-line array-callback-return
                                        rpdata?.works?.map((item, index) => {
                                            const expert = item?.service;
                                            switch (cardProduct) {
                                                case `${expert}`:
                                                    return (

                                                        <CardMenu
                                                            key={index}
                                                            imagen={item.gallery?.[0]}
                                                            name={item.name}
                                                            price={item.description}
                                                            phone={rpdata?.dbPrincipal?.phones?.[0]?.phone.replaceAll("-","")}
                                                            />
                                                    )
                                                default:
                                                // do nothing
                                            }
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Busqueda